<template>
  <!-- 废弃页面  -->
  <div class="content" :style="{backgroundImage:'url(' + leadPageUrl + ')'}">
    <div class="appChild">
      <div class="info">填写个人信息</div>
      <div class="titlePrompt">
        <div v-if="isShow" class="information">
          <p class="informationTitle">申请人姓名：</p>
          <input v-model="form.name" class="informationInp" type="text">
        </div>
        <div v-if="isShow" class="van-hairline--bottom" />
        <div v-if="isShow" class="information">
          <p class="informationTitle">身份证 ：</p>
          <input v-model="form.idEntityNo" class="informationInp" type="text">
        </div>
        <div v-if="isShow" class="van-hairline--bottom" />
        <div class="information">
          <p class="informationTitle">手机号码：</p>
          <input v-model="form.mobile" class="informationInp" type="number">
        </div>
        <div class="van-hairline--bottom" />
        <div class="information">
          <p class="informationTitle">短信验证码：</p>
          <input v-model="form.code" class="informationInp" style="width: 45%;" type="number">
          <button class="codeTextStyle" :style="{color:codeColor}" :disabled="disabled" @click="codeTextClick">
            {{ codeText }}
          </button>
        </div>
        <div class="van-hairline--bottom" />
      </div>
      <div class="serviceWrap">
        <van-checkbox v-model="checked" icon-size="15px">
          同意
          <span class="" style="color:#1F5DEE;" @click="goService">《服务协议》</span>
          所有条款
        </van-checkbox>
      </div>
      <div class="sweetPromptWrap">
        <p class="sweetPromptWrapText1">温馨提示：</p>
        <p class="sweetPromptWrapText1">在本平台申请信用卡不收取任何费用，凡索取费用均为诈骗行为，
          申请信用卡授信额度与个人资质相关，凡承诺100%下卡或10万以
          上大额信用卡申请的都是骗子，请不要相信！</p>
      </div>
      <div class="saveBtnWrap">
        <button class="saveBtn" @click="submit">确认无误</button>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { Checkbox, CheckboxGroup, Toast } from 'vant'

Vue.use(Checkbox)
  .use(CheckboxGroup)
  .use(Toast)

import {
  setSMSCode,
  ModifyAppInfo,
  createOrder
} from '@/services/comeOn.js'

export default {
  name: 'ApplicantInfo',
  data() {
    return {
      defaultBgcUrl: 'https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-11-18/15/yuelvhuiAtB3d720tX1605685525.png',
      leadPageUrl: '',
      isShow: true,
      codeText: '获取验证码',
      codeNumber: 60,
      form: {
        mid: '',
        mobile: '', // 手机号
        name: '', // 名字
        idEntityNo: '', // 身份证
        code: ''// 手机验证码
      },
      disabled: false,
      checked: true, // 服务协议
      codeColor: '#1F5DEE',
      sourceSign: '2095CE15', // 渠道编码
      thirdSign: '', // 三方字符串
      sharer: ''// 分享者id
    }
  },
  created() {
    const {
      leadPageUrl = this.defaultBgcUrl,
      bankCardId = '',
      typeId = null,
      bankId = '',
      sourceSign = '2095CE15',
      thirdSign,
      sharer,
      gateWayId
    } = this.$route.query
    this.leadPageUrl = leadPageUrl // 背景图
    this.bankCardId = bankCardId // 银行标识2
    this.bankId = bankId // 银行id
    this.gateWayId = gateWayId ? Number(gateWayId) : ''// gateWayId
    if (this.gateWayId === 1) {
      this.isShow = false
    }
    // 渠道编码
    this.sourceSign = sourceSign
    // 三方标识
    this.thirdSign = thirdSign
    // 分享者id
    this.sharer = sharer
    // typeId
    this.typeId = typeId ? Number(typeId) : 2
  },
  mounted() {

  },
  methods: {
    // 添加信息
    submit() {
      const that = this
      if (this.form.name === '') {
        Toast('请输入申请人姓名')
        return
      } else if (this.form.idEntityNo === '') {
        Toast('请输入身份证信息')
        return
      } else if (this.form.mobile === '') {
        Toast('请输入手机号')
        return
      } else if (this.form.code === '') {
        Toast('请获取手机验证码并填写')
        return
      } else if (this.checked === false) {
        Toast('请阅读服务协议并选中')
        return
      }
      this.form.mid = window.localStorage.getItem('uid')
      ModifyAppInfo(this.form).then(res => {
        if (Number(res.code) === 200) {
          // 创建订单并跳转到3方页面
          if (that.gateWayId === 1) {
            that.form.name = '京东卡'
            that.form.idEntityNo = Math.floor(Math.random() * 999999999999999999)// 18位随机数字
          }
          const createOrderData = {
            merMobile: that.form.mobile, // 手机号
            merIdentityNo: that.form.idEntityNo, // 身份证（银行列表gateWayId值为1时，这里传18位不可重复的随机数）
            merName: that.form.name, // 姓名（银行列表gateWayId值为1时，这里传固定的名称为'京东卡'）
            bankCardId: that.bankCardId, // 银行标识2（银行列表中的merCardId字段）
            bankId: that.bankId, // 银行id
            gateWayId: that.gateWayId, // 如果返回1页面不显示填写姓名和身份证
            thirdSign: that.thirdSign, // 三方标识
            sourceSign: that.sourceSign, // 渠道编码
            mid: window.localStorage.getItem('uid'), // 用户id（如果渠道编码是邻居团的则不传）
            sharer: that.sharer, // 分享者id
            typeId: that.typeId,
            bid: that.bankId
          }
          createOrder(createOrderData).then(res => {
            if (Number(res.code) === 200) {
              window.location.href = res.data.url
            } else {
              Toast(res.msg)
            }
          })
        } else {
          Toast(res.msg)
        }
      })
    },
    // get验证码
    codeTextClick() {
      const that = this
      if (this.form.mobile === '') {
        Toast('请填写手机号')
        return false
      } else {
        setSMSCode({
          mobile: that.form.mobile
        }).then(res => {
          if (Number(res.code) === 200) {
            Toast(res.msg)
            that.disabled = true// 禁用按钮
            that.codeColor = '#ccc'
            that.codeText = that.codeNumber + 's'
            const Interval = setInterval(function() {
              (that.codeNumber)--
              that.codeText = that.codeNumber + 's'
              if (that.codeNumber === 0) {
                clearInterval(Interval)
                that.codeText = '获取验证码'
                that.disabled = false// 启用按钮
                that.codeColor = '#1F5DEE'
                that.codeNumber = 60
              }
            }, 1000)
          } else {
            Toast(res.msg)
          }
        })
      }
    },
    // 服务协议
    goService() {
      this.$router.push({
        path: '/cardServiceIndex'
      })
    }
  }
}
</script>

<style lang="less" scoped>
html, body, #app {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: #40188E;
}

.content {
  position: fixed;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-color: #40188E;
}

.info {
  text-align: center;
  color: #13002F;
  font-size: 14px;
  font-weight: 700;
}

.appChild {
  position: absolute;
  width: 88%;
  bottom: 101px;
  left: 6%;
  /*margin: 0 auto;*/
  background-color: #fff;
  border-radius: 7px;
  z-index: 3;
  padding: 20px 31px 16px 36px;
}

.titlePrompt {
  font-size: 15px;
  line-height: 32px;
  margin-top: 18px;
  width: 100%;
}

.information {
  display: flex;
  height: 32px;
  line-height: 32px;
}

.informationTitle {
  width: 30%;
  -webkit-text-size-adjust: none;
  font-size: 12px;
  color: #666;
  text-align: left;
}

.informationInp {
  background: none;
  outline: none;
  border: none;
  font-size: 12px;
  color: #000;
}

.informationInp::-webkit-input-placeholder { /* WebKit browsers */
  color: #CBCBCB;
  font-size: 12px;
}

.TermsChoiceWrap {
  height: 20px;
  padding: 10px 20px;
}

.TermsChoiceRadio {
  width: 15px;
  height: 15px;
  margin-right: 5px;
}

.TermsChoiceText1 {
  display: inline-block;
  color: #BCBCBD;
  line-height: 20px;
}

.TermsChoiceText2 {
  display: inline-block;
  color: #88BBDF;
}

.codeTextStyle {
  background: none;
  border: none;
  width: 25%;
  padding: 0;
  /*color: #1F5DEE;*/
  font-size: 12px;
  text-align: center;
}

.serviceWrap {
  font-size: 12px;
  color: #999;
  margin-top: 13px;
}

.sweetPromptWrap {
  text-align: left;
  padding: 10px;
  color: #1F5DEE;
  font-size: 12px;
  line-height: 15px;
}

.sweetPromptWrapText1 {

}

.saveBtnWrap {
  width: 100%;
  height: 70px;
  position: relative;
  margin-top: 10px;
}

.saveBtn {
  border: none;
  position: absolute;
  left: 50%;
  margin-left: -75px;
  width: 175px;
  height: 43px;
  background-color: #1468FF;
  text-align: center;
  line-height: 40px;
  font-size: 13px;
  font-weight: 500;
  color: #fff;
  border-radius: 7px;
}

button:focus {
  outline: 0;
}
</style>
